import { createApp } from 'vue';
import App from './App.vue';
import router from './router';

import 'animate.css';
import '@/assets/css/global.css';

import AMapLoader from "@amap/amap-jsapi-loader";

const app = createApp(App);
app.config.globalProperties = {
  $loadAMap: async (plugins = []) => {
    const AMap = await AMapLoader.load({
      key: "c5bec4e4f18027246e25f094f623b249",
      version: "2.0",
      plugins, // 指定要加载的插件列表，缺省时默认为空
    });
    if (!app.config.globalProperties.$AMap)
      return app.config.globalProperties.$AMap = AMap;
    return AMap;
  },

  //向Flutter端发送消息
  $sendMessage: (type, data) => {
    //window.amapChannel?.postMessage(JSON.stringify({ type, data }));
    if(window.amapChannel){
      window.amapChannel.postMessage(JSON.stringify({ type, data }));
    }
  },

  $hhmmssSSS: (time) => {
    if (!time) return '00:00:00.000';
    const date = new Date(time);
    const hh = date.getHours();
    const mm = date.getMinutes();
    const ss = date.getSeconds();
    const SSS = date.getMilliseconds();
    return `${hh < 10 ? '0' + hh : hh}:${mm < 10 ? '0' + mm : mm}:${ss < 10 ? '0' + ss : ss}.${SSS < 10 ? '00' + SSS : SSS < 100 ? '0' + SSS : SSS}`;
  },
};

app.use(router);
window.app = app.mount('#app');