import { createRouter, createWebHashHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/tran', //历史轨迹播放页面(机构端)
    name: 'tran',
    component: () => import('../views/HistoryTran.vue')
  },
  {
    path: '/tran2', //历史轨迹播放页面(用户端)
    name: 'tran2',
    component: () => import('../views/HistoryTran2.vue')
  },
  {
    path: '/tran3', //历史轨迹播放页面(h5页面)
    name: 'tran3',
    component: () => import('../views/HistoryTran3.vue')
  },
  {
    path: '/clock', //打卡页面
    name: 'clock',
    component: () => import('../views/clock.vue')
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

export default router;
