<template>
  <div id="home">
    <header>欢迎使用指寻APP</header>
    <footer>Copyright &copy; 趣寻 2022</footer>
  </div>
</template>

<style scoped>
#home {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

header{
  font-size: 2rem;
  font-weight: bold;
  line-height: 4rem;
  color: var(--primary);
  text-shadow: .06rem .06rem 0 var(--dark);
}

footer{
  font-size: 1rem;
  line-height: 2rem;
  color: var(--secondary);
}
</style>